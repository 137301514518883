@import "~etk-design-tokens/scss/etk-tokens";

@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Lato:wght@400;600;700&display=swap');

$calculator-colors-brand-black: hsl(0, 0%, 20%);
$calculator-colors-brand-blue: hsl(204, 100%, 37%);
$calculator-colors-brand-orange: hsl(13, 76%, 52%);
$calculator-colors-sub-header-black: #333333;
$calculator-colors-brand-light-orange: hsl(32, 100%, 95%);
$calculator-colors-brand-lighter-orange: hsl(33, 100%, 98%);
$calculator-colors-brand-dark-gray: hsl(0, 0%, 56%);
$calculator-colors-brand-gray: hsl(0, 0%, 96%);
$calculator-colors-brand-light-gray: hsl(180, 2%, 89%);

$calculator-colors-highlight: hsl(14, 80%, 43%);
$calculator-colors-error: hsl(348, 94%, 42%);
$calculator-colors-error-background: hsl(0, 100%, 98%);

$body-color: $calculator-colors-brand-black;
$theme-colors: (
        "primary": $calculator-colors-brand-blue,
        "secondary": $etk-colors-gray-dark35,
        "info": $etk-colors-brand-base,
        "success": $etk-colors-success-base,
        "warning": $etk-colors-warning-base,
        "danger": $etk-colors-danger-base
);

$font-family-base: 'Lato', sans-serif;
$headings-font-weight: 700;
$headings-color: $calculator-colors-brand-orange;

// Based on typical default for browsers of 16px
$calculator-base-font-size: 1rem; // 16px
$h1-font-size: 1.5rem; // 24px
$h2-font-size: 1.25rem; // 20px

@mixin default-border {
  border: 1px solid $calculator-colors-brand-dark-gray;
  border-radius: 0;
}

@mixin border-highlight {
  box-shadow: 0 0 0 1px $calculator-colors-brand-dark-gray;
}

html {
  font-size: $calculator-base-font-size;
}

@import "~bootstrap/scss/bootstrap";
