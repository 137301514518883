@import "etk-bootstrap";

.skip-to-content {
  margin: 0.125em;
}

.main-container {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.content-container {
  // 706px (44.125em) + 15px (0.9375em)*2 of padding
  max-width: 46em;
}

.alert-danger {
  border-color: $calculator-colors-error;
  background-color: $calculator-colors-error-background;
  border-radius: 0;
  color: $calculator-colors-error;

  h2 {
    color: $calculator-colors-error;
    border-color: $calculator-colors-error;
  }
  a {
    color: $calculator-colors-error;
  }
}

.page-header {
  background: $calculator-colors-brand-blue;
  color: white;

  .header-content {
    display: flex;
    flex: 1;
  }

  .header-title {
    font-size: 2rem;
    font-family: "Bree Serif", serif;
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  .language-select {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-top: 1rem;
    margin-left: auto;
    padding: 0;
    font-size: 1.125rem;

    li {
      border-right: white solid 1px;
      padding: 0 0.5em;

      &:last-child {
        border-right: none;
      }
    }

    a, a:hover, a:active {
      color: white;
    }

    a.selected {
      font-weight: 600;
    }
  }
}

.calculator-container {
  .ingress {
    font-weight: 600;
  }
}

.form-container {
  .calculator-form {
    .industry-select, .select-input-group {
      @mixin focused-option {
        @include default-border;
        border-width: 2px;
      }

      .select-industry__control,
      .select-input__control {
        @include default-border;
      }

      .select-industry__control--is-focused,
      .select-industry__control:hover,
      label:hover ~ .select-industry .select-industry__control,
      .select-input__control--is-focused,
      .select-input__control:hover,
      label:hover ~ .select-input .select-input__control{
        @include border-highlight;
      }

      .select-industry__input-container, .select-industry__placeholder,
      .select-input__input-container, .select-input__placeholder{
        color: $calculator-colors-brand-black;
        font-size: $calculator-base-font-size;
      }

      .select-industry__indicator,
      .select-input__indicator{
        color: $calculator-colors-brand-black;
      }

      .select-industry__indicator-separator,
      .select-input__indicator-separator{
        display: none;
      }

      .select-industry__menu,
      .select-input__menu{
        border-radius: 0;
        z-index: 11;
      }

      .select-industry__option,
      .select-input__option{
        transition: background 60ms;
        min-height: 60px;
        display: flex;
        align-items: center;

        &:hover {
          @include focused-option;
        }
      }

      .select-industry__option--is-focused,
      .select-input__option--is-focused{
        @include focused-option;
        background: white;
      }

      .select-industry__option--is-selected,
      .select-input__option--is-selected{
        background: $calculator-colors-brand-blue;
        color: white;

        .select-industry__option--is-focused,
        .select-input__option--is-focused{
          background: $calculator-colors-brand-blue;
        }
      }

      .hidden, .hidden:invalid, .hidden:valid {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }

    input.value-input {
      @include default-border;

      font-size: $calculator-base-font-size;

      &::placeholder {
        font-style: italic;
      }

      &:focus, &:hover, &:active {
        @include border-highlight;
      }
      &:valid {
        border-color: $calculator-colors-brand-dark-gray;
      }
      &:invalid, &:valid {
        background-image: none;
      }
      &.changed {
        &.invalid, &:invalid, &:invalid + .input-group-text {
          border-color: $calculator-colors-error;
          &:hover, &:focus, &:active {
            box-shadow: 0 0 0 1px $calculator-colors-error;
          }
        }
      }
    }
    .was-not-validated {
      input.value-input.changed {
        &.invalid, &.is-invalid, &:invalid, &:invalid + .input-group-text {
          background: white;
          border-color: $calculator-colors-brand-dark-gray;
          &:focus, &:hover, &:active {
            @include border-highlight;
          }
        }
      }
      .invalid-feedback {
        display: none;
        visibility: hidden;
      }
    }

    .input-group-append {
      position: absolute;
      // Take into account sibling inputs border
      right: 1px;
      top: 1px;
      border: none;
      z-index: 10;

      span {
        border: none;
        background: $calculator-colors-brand-gray;
        font-size: $calculator-base-font-size;
      }
    }

    label span.required {
      padding-left: 0.25em;
      color: $calculator-colors-brand-orange;
    }

    .form-group {
      width: 100%;
    }

    .value-input {
      .input-group {
        position: relative;
      }

      .input-text {
        position: absolute;
        right: 0.5em;
        top: 0.35em;
        font-size: $calculator-base-font-size;
        z-index: 10;
      }
    }

    .invalid-feedback {
      color: $calculator-colors-error;
      font-weight: 600;
      font-size: $calculator-base-font-size;
    }

    button:not(.btn-link) {
      background: $calculator-colors-brand-orange;
      color: white;
      border: none;
      border-radius: 2rem;
      width: 100%;
      font-weight: 700;
      font-size: 1.25rem; // 20px
    }

    button:active:not(.btn-link), button:focus:not(.btn-link), button:hover:not(.btn-link) {
      background: $calculator-colors-highlight;
      box-shadow: none;
    }
  }

  .calculator-form.was-validated {
    .value-input {
      input:invalid, input:valid {
        background-image: none;
      }
      input:invalid, input:invalid + .input-group-text {
        border-color: $calculator-colors-error;
      }
    }

    .select-industry.invalid .select-industry__control  {
      border-color: $calculator-colors-error;

      &:hover, &.select-industry__control--is-focused {
        box-shadow: 0 0 0 1px $calculator-colors-error;
      }
    }
  }
}

.help-text-container {
  margin-top: -0.5em;
  margin-bottom: 1em;

  .card {
    border: none;
    margin-bottom: 0.5em;
    background-color: transparent;
  }

  .card-header {
    border: none;
    background-color: transparent;
    padding: 0;

    button {
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;

      .icon {
        padding-left: 0.25em;
        margin-top: 0.25em;
      }

      &:focus {
        box-shadow: none;
      }

      &.btn-link {
        text-align:  left !important;;
      }
    }
  }

  .card-body {
    padding: 0.5em 2em;
    background-color: #FFF2E3;
  }
}

.explanation-container {
  flex: 1;

  .accordion {
    .card {
      border: 1px solid $calculator-colors-brand-dark-gray;
      border-radius: 0;
    }

    .card-header {
      background: $calculator-colors-brand-light-orange;
      padding: 0;

      button {
        color: $calculator-colors-brand-black;
        font-size: 1.1875rem; // 19px
        font-weight: 700;
        width: 100%;
        height: 100%;
        display: flex;

        .icon {
          margin-left: auto;
          margin-top: 0.25em;
        }
      }
    }

    .card-body {
      background: $calculator-colors-brand-lighter-orange;
    }
  }
}

.results-container {
  background: white;
  padding: 0.5rem 0;
  margin-bottom: 0.5em;

  .result-value {
    background: $calculator-colors-brand-gray;
    padding: 0.5rem 1rem;
    font-weight: 700;
    font-size: 1.375rem; // 22px
  }
  .result-section {
    margin-bottom: 1em;

    .help-text-container {
      margin-bottom: 0;

      .card {
        margin-bottom: 0.25em;
      }

      .collapse.show {
        margin-bottom: 0.25em;
      }
    }

    .result-value {
      margin-bottom: 0.5rem;
    }
  }
  h2 {
    font-size: 1.1875rem;
  }
  label.result-label {
    font-weight: bold;
  }
  .calculationInfo {
    h3 {
      color: $calculator-colors-sub-header-black;
    }
    h3, p {
      font-size: 0.9375rem;
      margin-bottom: 0.625rem;
    }
  }

  .divider {
    width: 100%;
    border-top: 2px dotted $calculator-colors-brand-orange;
    border-bottom: none;
  }
}

.chart-container {
  position: relative;
  background: white;
  padding: 0.5rem 0;
  margin-bottom: 0.5em;
  height: 40vh;
  min-height: 225px;
  max-height: 450px;
}

.popover.show {
  min-width: 30rem !important;
}

.help-icon {
  display: inline-block;
  margin-left: 0.3rem;
  &.btn.btn-link {
    padding: 0;
    margin-top: -0.1rem;
    font-size: 1.1rem;
  }
}

.toggle-group {
  margin-top: 0.5rem;
  margin-bottom: 0;

  .toggle {
    .form-check-input {
      margin-top: 0;
      margin-left: 0;
      width: 1.5rem;
      height: 1.5rem;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;

    input {
      @include default-border;

      &:focus, &:hover, &:active {
        @include border-highlight;
      }

      &:checked {
        accent-color: $calculator-colors-brand-dark-gray;
      }

      &.is-invalid:focus {
        @include border-highlight;
      }
    }

    input[type=checkbox] span:after {
      content: " ";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      visibility: visible;
    }
    input[type=checkbox]:checked span:after {
      content: "\2714";
      color: white;
      text-align: center;
      font-size: 1rem;
      width: 100%;
    }
  }

  .toggle-content {
    &.error-label {
      margin-left: 2rem;
    }
    h2 {
      font-size: 1.15rem;
    }
    .when-selected {
      h2 {
        margin-top: 0.5rem;
      }
    }
  }

}

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
